import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LBgSect,
  CPageNav,
  CFaqList,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '/faq/',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <CFaqList
            hash=""
            data={[
              {
                q: '横浜ランドマークタワーの高さは何ｍですか?',
                a: <>296ｍです。ホテルの客室はすべて210ｍ以上にございます。</>,
              },
              {
                q: 'バリアフリーについてはどのような対応をされていますか?',
                a: (
                  <>
                    スロープやエレベーターなどを各所に設置してありますので、車椅子の方にもご利用いただきやすい設計になっております。
                    また、ホテルをご利用のお客様には車椅子の貸出しも承っております。ご希望の際はご予約をお願いいたします。
                    <br />
                    ●客室
                    <br />
                    52Fに専用ルームが6部屋ございます。客室はゆったりとお過ごしいただけるデラックスツインルーム（41m²）で、バスルームに手すりを設置するなどご利用いただきやすいつくりになっております。
                    <br />
                    ●レストラン＆バー
                    <br />
                    全てのレストラン＆バーは、車椅子の方もご利用いただけます。
                    <br />
                    ●化粧室
                    <br />
                    ホテル棟は70F・68F・B1Fの3フロアに、宴会棟は2F・3F・4Fの3フロアにございます。
                  </>
                ),
              },
              {
                q: '喫煙所はありますか?',
                a: <>ホテル棟1F・68F、宴会棟2Fに喫煙所がございます。</>,
              },
              {
                q: 'ベビーシッターの利用は可能ですか?',
                a: (
                  <>
                    ホテルをご利用のお客様には、ベビーシッターの手配を承っております。
                    <br />
                    詳しくは
                    <Link to="/facilities/baby/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: 'ペットの同伴は可能ですか?',
                a: (
                  <>
                    盲導犬や介助犬、聴導犬などの補助犬以外の同伴はお断りしております。
                  </>
                ),
              },
              {
                q: 'wifiは利用できますか?',
                a: (
                  <>
                    ホテルご利用のお客様は無料でご利用いただけます。
                    <br />
                    詳しくは
                    <Link to="/facilities/business/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
